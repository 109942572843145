<template>
  <div>
    <div v-if="isBusy" id="lottie">
      <div class="loading-logo">
        <img style="margin-left: -4rem;"  alt="Logo" src="/logo-transparent.png" />
      </div>
      <div class="loading">
        <div class="effect-1 effects" />
        <div class="effect-2 effects" />
        <div class="effect-3 effects" />
      </div>
    </div>
    <div v-else>
      <h2 style="margin-top: 5px">
        {{ opportunity.address + ", " + opportunity.geography }}
      </h2>
      <b-row style="margin-top: 2.5px">
        <b-col cols="6" style="padding-left: 2rem">
          <b-row>
            <uil-suitcase class="logo" size="15px" />
            <p style="padding-right: 20px; padding-left: 5px">
              {{ opportunity.account_name }}
            </p>
            <uil-ruler class="logo" size="15px" />
            <p style="padding-left: 5px">
              {{
                opportunity.rentable_sqft.toLocaleString("en", { useGrouping: true }) +
                " SQFT"
              }}
            </p>
          </b-row>
        </b-col>
        <b-col align-self="end" cols="6" style="text-align: end; padding-bottom: 20px">
          <div
            style="margin-right: 10px"
            class="d-flex justify-content-end"
            v-if="!opportunity.ccdc_signed"
          >
            <div :id="'disabled_mark_won'">
              <b-button
                v-b-modal.tfr-mark
                variant="outline-primary"
                :disabled="markAsWonStatus"
                style="margin-right: 10px; color:  rgba(92, 177, 231, 1) "
                v-if="
                  opportunity.quotes_count >= 1 && !opportunity.submitted_for_handover
                "
              >
                Mark as Won
              </b-button>
            </div>
            <b-tooltip
              v-if="markAsWonStatus"
              :target="'disabled_mark_won'"
              title="Marking an opportunity as won must have at least one quote reviewed and completed"
            />
<!--            <b-button-->
<!--              style="margin-right: 10px"-->
<!--              variant="outline-primary"-->
<!--              v-if="opportunity.marked_as_active && opportunity.submitted_for_handover"-->
<!--              @click="ccdcSigned()"-->
<!--            >-->
<!--              <uil-check class="logo" size="15" />-->
<!--              Mark Contract as Signed-->
<!--            </b-button>-->

            <b-button
              v-b-modal.tfr-creation
              variant="primary"
              v-if="opportunity.tfrs.length != 0"
            >
              Request New Test Fit
            </b-button>
            <b-dropdown
              no-caret
              toggle-class="rounded-square p-0 "
              style="width: 38px; height: 38px; margin-left: 10px"
              variant="outline-secondary"
              offset="-175"
            >
              <template #button-content>
                <feather-icon class="btn-icon" icon="MoreVerticalIcon" size="15" />
              </template>
              <b-dropdown-item
                :href="`/opportunities/${opportunityId}/compare-quotes/`"
                :disabled="opportunity.quotes_count <= 1"
              >
                <uil-chart-line class="logo" size="15" />
                Compare Quotes
              </b-dropdown-item>
              <b-dropdown-item
                @click="revertSubmittedHandover"
                v-if="opportunity.submitted_for_handover && isAdmin == 1"
              >
                <uil-check class="logo" size="15" />
                Revert Submitted for Handover
              </b-dropdown-item>
            </b-dropdown>
          </div>
          <div v-if="opportunity.ccdc_signed && isAdmin == 1">
            <b-button
              variant="outline-secondary"
              style="margin-right: 10px"
              @click="revertMarkCcdcSigned()"
            >
              Revert Mark Contract as Signed (Admins Only)
            </b-button>
          </div>
        </b-col>
      </b-row>
      <revisions
        :opportunity="opportunity"
        :revisionProp="revision"
        :selectedRequirementsProp="selectedRequirements"
        :selectedIndexProp="selectedIndex"
        :activity_logs="opportunity.activity_logs"
        @updated-tfrs="updateTfrs"
        @tfrReload="getOpportunity"
      />
    </div>
    <!-- <div v-else>
      <b-row>
        <b-col cols="8" class="mb-0 py-1">
          <h1>
            <b>{{ opportunity.address }}, {{ opportunity.geography }} </b>
          </h1>
        </b-col>
        <b-col cols="4" class="mb-0 py-1" align-self="end">
          <div style="margin-right: 10px" class="d-flex justify-content-end">
            <div
              style="margin-right: 10px"
              class="d-flex justify-content-end"
              v-if="!opportunity.ccdc_signed"
            >
              <div :id="'disabled_mark_won'">
                <b-button
                  v-b-modal.tfr-mark
                  class="custom_btn"
                  variant="primary"
                  :disabled="markAsWonStatus"
                  style="font-size: 14px"
                  v-if="
                    opportunity.quotes.length >= 1 && !opportunity.submitted_for_handover
                  "
                >
                  Mark as Won
                </b-button>
              </div>
            </div>
            <b-dropdown
              right
              no-caret
              toggle-class="rounded-square p-0 "
              style="width: 38px; height: 38px; margin-left: 5px"
              variant="outline-secondary"
            >
              <template #button-content>
                <feather-icon class="btn-icon" icon="MoreVerticalIcon" size="15" />
              </template>
              <b-dropdown-item
                :href="`/opportunities/${this.$route.params.id}/compare-quotes/`"
                style="font-size: 14px"
              >
                <uil-chart-line class="logo" size="15" />
                Compare Quotes
              </b-dropdown-item>
              <b-dropdown-item
                @click="revertSubmittedHandover"
                v-if="opportunity.submitted_for_handover && isAdmin == 1"
                style="font-size: 14px"
              >
                <uil-check class="logo" size="15" />
                Revert Submitted for Handover
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </b-col>
        <b-col cols="9" class="mt-0 pt-0">
          <div>
            <b-row>
              <b-col cols="12" class="pt-">
                <b-card v-if="!opportunity.ccdc_signed">
                  <b-row>
                    <b-col cols="8">
                      <h5><strong>Revisions</strong></h5>
                    </b-col>
                    <b-col cols="4" class="text-right">
                      <b-button
                        v-b-modal.tfr-creation
                        variant="outline-primary"
                        class="custom_btn text-primary"
                        style="font-size: 14px"
                        >Create Revision</b-button
                      >
                    </b-col>
                  </b-row>
                  <div class="mt-2">
                    <v-data-table
                      v-if="opportunity.tfrs.length > 0"
                      :items="opportunity.tfrs"
                      :headers="fields"
                      @click:row="toRevisionPage"
                    >
                      <template v-slot:item.quote_totals.project_total="{ item }">
                        ${{
                          item.quote_totals.project_total
                            ? Number(item.quote_totals.project_total).toLocaleString(
                                "en-CA",
                                {
                                  minimumFractionDigits: 2,
                                }
                              )
                            : "0.00"
                        }}
                      </template>
                      <template v-slot:item.tfr="{ item }">
                        <strong style="color: #6e6b7b">{{ item.tfr }}</strong>
                      </template>
                      <template
                        v-slot:item.quote_totals.project_dollar_per_sqft="{ item }"
                      >
                        ${{
                          item.quote_totals.project_dollar_per_sqft
                            ? Number(
                                item.quote_totals.project_dollar_per_sqft
                              ).toLocaleString("en-CA", {
                                minimumFractionDigits: 2,
                              })
                            : "0.00"
                        }}
                      </template>
                      <template v-slot:item.tfr_status="{ item }">
                        <b-badge
                          pill
                          variant="primary"
                          :class="
                            item.tfr_status != null
                              ? badgeClass(item.tfr_status)
                              : 'draft'
                          "
                        >
                          {{ item.tfr_status != null ? item.tfr_status : "Draft" }}
                        </b-badge>
                      </template>
                      <template v-slot:item.updated_at="{ item }">
                        <p>
                          <span
                            :id="'disabled-wrapper' + item.id"
                            class="d-inline-block"
                            style="margin-top: 10px; margin-bottom: -10px"
                            tabindex="0"
                          >
                            <b-avatar
                              v-if="
                                initials(item.updated_by) == 'PL' ||
                                initials(item.updated_by) == 'AL' ||
                                initials(item.updated_by) == 'SR' ||
                                initials(item.updated_by) == 'TR'
                              "
                              size="30"
                              variant="light-primary"
                              badge
                              :src="require('@/assets/images/avatars/gandalf.jpeg')"
                              class="badge-minimal"
                              badge-variant="success"
                            />
                            <b-avatar
                              v-else-if="initials(item.updated_by) == 'EG'"
                              size="30"
                              variant="light-primary"
                              badge
                              :src="require('@/assets/images/avatars/grumpy.jpg')"
                              class="badge-minimal"
                              badge-variant="success"
                            />
                            <b-avatar
                              v-else
                              size="30"
                              :variant="
                                item.updated_by == userName
                                  ? 'light-primary'
                                  : 'light-secondary'
                              "
                              :text="
                                initials(
                                  item.updated_by != null
                                    ? item.updated_by
                                    : item.created_by
                                )
                              "
                              class="badge-minimal"
                            />
                          </span>
                          {{ item.updated_by != null ? "Updated" : "Created" }}
                          {{ formatDatetime(item.updated_at) }}
                        </p>
                        <b-tooltip :target="'disabled-wrapper' + item.id">{{
                          item.updated_by != null ? item.updated_by : item.created_by
                        }}</b-tooltip>
                      </template>
                    </v-data-table>
                    <div v-else class="text-center">
                      <h3 class="text-muted" style="font-weight: 600">
                        No Revisions to Show
                      </h3>
                      <p class="text-muted">
                        Create a Revision order to handle changes in project scope
                        <br />before a contract has been signed.
                      </p>
                    </div>
                  </div>
                </b-card>
              </b-col>
            </b-row>
          </div>
        </b-col>
        <b-col cols="3" class="mt-0 pt-0">
          <b-card class="overflow-auto" style="max-height: 73.2vh;">
            <h5 class="mb-2"><strong>Activity log</strong></h5>
            <log-card :activity_logs="opportunity.activity_logs" />
          </b-card>
        </b-col>
      </b-row>
    </div> -->
    <b-modal
      id="tfr-creation"
      ref="tfr-creation"
      scrollable
      size="lg"
      title="Create a New Revision"
      :hide-footer="true"
      :no-close-on-backdrop="true"
    >
      <create-tfr-modal
        :opp="opportunity"
        @createdTfr="createdTfr"
        @errorModal="errorModal"
      />
      <template #modal-footer="{ ok }">
        <b-button size="sm" style="display: none" variant="success" @click="ok()">
          ok
        </b-button>
      </template>
    </b-modal>
    <b-modal
      ref="tfr-mark"
      id="tfr-mark"
      centered
      title="Mark Project as Won"
      :hide-footer="true"
      :no-close-on-backdrop="true"
    >
      <mark-as-won-modal
        :won_tfr="wonTfr"
        @wonAction="markAsWon"
        @closeAction="closeMarkAsWon"
      />
      <template #modal-footer="{ ok }">
        <b-button size="sm" style="display: none" variant="success" @click="ok()">
          ok
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { UilRuler, UilSuitcase, UilCheck, UilChartLine } from "@iconscout/vue-unicons";
import {
  BAvatar,
  BButton,
  BCard,
  BCol,
  BDropdown,
  BDropdownItem,
  BRow,
  BSpinner,
  BTable,
  BTooltip,
  VBModal,
  BBadge,
  BTab,
  BTabs,
  BCardText,
  BListGroup,
  BListGroupItem,
  VBToggle,
  BCollapse,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BInputGroupPrepend,
  BInputGroup,
} from "bootstrap-vue";

// import { VDataTable } from "vuetify/lib";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import moment from "moment";
import UploadModalDuplication from "../Quotes/UploadModalDuplication.vue";
import CreateTfrModal from "../ActiveProject/Components/Modals/CreateNewTfr.vue";
import EditTfrModal from "../Tfrs/Components/EditTfrModal.vue";
import MarkAsWonModal from "../Tfrs/Components/MarkAsWonModal.vue";
import Ripple from "vue-ripple-directive";
import draggable from "vuedraggable";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import revisions from "./Components/Revisions/Revisions.vue";
import ChangeOrders from "./Components/ChangeOrders/ChangeOrders.vue";
import MarkAsWonModalVue from "../Tfrs/Components/MarkAsWonModal.vue";
import LogCard from "../ActiveProject/Components/LogCard.vue";

export default {
  components: {
    BCard,
    BTable,
    BCol,
    BRow,
    BSpinner,
    UilRuler,
    UilSuitcase,
    BButton,
    BTooltip,
    BDropdown,
    BDropdownItem,
    BAvatar,
    UploadModalDuplication,
    BBadge,
    CreateTfrModal,
    BTab,
    BTabs,
    BCardText,
    BListGroupItem,
    BCollapse,
    draggable,
    BFormGroup,
    BFormTextarea,
    BFormInput,
    BListGroup,
    BInputGroupPrepend,
    BInputGroup,
    ValidationProvider,
    ValidationObserver,
    EditTfrModal,
    revisions,
    ChangeOrders,
    UilCheck,
    UilChartLine,
    MarkAsWonModal,
    LogCard,
    // VDataTable,
  },
  directives: {
    "b-modal": VBModal,
    "b-toggle": VBToggle,
    Ripple,
  },
  data() {
    return {
      opportunityId: this.$route.params.id,
      opportunity: {
        tfrs: [],
        quotes: [],
      },
      isBusy: true,
      tableIsBusy: true,
      revision: [],
      selectedRequirements: [],
      selectedIndex: 0,
      modalIsBusy: false,
      routeRevisionId: this.$route.params.revision,
      userName: localStorage.getItem("name"),
      isAdmin: localStorage.getItem("isAdmin"),
      activeQuote: {},
      fields: [
        {
          text: "ID",
          value: "tfr",
        },
        {
          text: "QUOTE TOTAL",
          value: "quote_totals.project_total",
        },
        {
          text: "$/SQFT",
          value: "quote_totals.project_dollar_per_sqft",
        },
        {
          text: "STATUS",
          value: "tfr_status",
        },
        {
          text: "UPDATED",
          value: "updated_at",
        },
      ],
      wonTfrsFiltered: [],
    };
  },
  computed: {
    activeQuoteRevision() {
      var activeQuoteRevision = this.opportunity.tfrs.find(
        (n) => n.marked_as_active == true
      );
      this.activeQuote = this.opportunity.quotes.find((n) => n.marked_as_active == true);
      return activeQuoteRevision;
    },
    markAsWonStatus() {
      let wonQuotes = [];
      wonQuotes = Array.isArray(this.opportunity.tfrs)
        ? this.opportunity.tfrs.filter((n) => n.tfr_status == "Quote Completed")
        : [];
      return wonQuotes.length == 0 ? true : false;
    },
    wonTfr: {
      get() {
        this.wonTfrsFiltered = Array.isArray(this.opportunity.tfrs)
          ? this.opportunity.tfrs.filter((n) => n.tfr_status == "Quote Completed")
          : [];
        return this.wonTfrsFiltered;
      },
      set(tfrs) {
        this.wonTfrsFiltered = tfrs;
      },
    },
  },
  beforeMount() {
    this.getOpportunity();
  },
  methods: {
    getOpportunity() {
      this.$http
        .get(`/opportunities/${this.opportunityId}`, {
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
        .then((response) => {
          this.opportunity = response.data;
          // this.quotes = this.opportunity.quotes;
          this.revision = this.opportunity.tfrs[0];
          this.selectedIndex = 0;
          this.selectedRequirements = this.revision.requirements;
          this.updateBreadcrumb();
          if (this.routeRevisionId) {
            const index = this.opportunity.tfrs
              .map((e) => e.tfr)
              .indexOf(this.routeRevisionId);
            this.revision = this.opportunity.tfrs[index];
            this.selectedRequirements = this.revision.requirements;
            this.selectedIndex = index;
          }
          if (this.opportunity.marked_as_active) {
            this.$router.push(`/active/${this.$route.params.id}`);
          }
          if (this.opportunity.sf_opportunity_number) {
            document.title = `Opportunity Revision List [${this.opportunity?.sf_opportunity_number}] ${this.opportunity.address}`;
          }
          this.isBusy = false;
          this.tableIsBusy = false;
        })
        .catch((error) => {
          this.isBusy = false;
          this.tableIsBusy = false;
          this.showToast("danger", error.response.data.message);
        });
    },
    updateBreadcrumb() {
      this.$route.meta.breadcrumb[0].to = "/opportunities";
      this.$route.meta.breadcrumb[0].active = true;
      this.$route.meta.breadcrumb[1].text = `${this.opportunity.address}, ${this.opportunity.geography}`;
      this.$route.meta.breadcrumb[1].active = false;
      this.$root.$emit("rerender-breadcrumb");
    },
    updatedTfr(variable) {
      this.revision = variable;
      this.opportunity.tfrs[this.selectedIndex] = variable;
      this.selectedRequirements = variable.requirements;
      this.showToast("success", "Revision Updated Successfully", "Revision Updated");
      this.$refs["tfr-update"].hide();
    },
    createdTfr(variable) {
      this.showToast("success", "Revision Created Successfully", "Revision Created");
      this.$refs["tfr-creation"].hide();
      this.revision = variable.tfr;
      this.opportunity = variable.opportunity;
      var tfrId = this.revision.id;
      var index = this.opportunity.tfrs.indexOf(
        this.opportunity.tfrs.filter(function (item) {
          return item.ID == tfrId;
        })[0]
      );
      this.selectedRequirements = this.revision.requirements;
      this.selectedIndex = 0;
    },
    markAsWon(tfr) {
      this.$http
        .get(`/opportunities/${this.opportunityId}/tfr/${tfr.id}/submit-for-handover`, {
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
        .then((response) => {
          this.showToast(
            "success",
            `${tfr.tfr} has been submitted for handover. This project has now been moved to Active Projects.`,
            `Handover Complete`
          );
          // this.opportunity.tfrs = response.data.data;
          // this.opportunity.submitted_for_handover = true;
          // this.opportunity.marked_as_active = true;
          // this.getOpportunity();
          this.$router.push(`/active/${this.opportunityId}`);
        })
        .catch((error) => {
          console.log(error);
          this.isBusy = false;
          this.tableIsBusy = false;
          this.showToast("danger", error.response.data.message);
        });

      this.$refs["tfr-mark"].hide();
    },
    closeMarkAsWon() {
      this.$refs["tfr-mark"].hide();
    },
    errorModal(variable) {
      this.showToast("danger", "Contact Digital for support.", "Something went wrong");
    },
    ccdcSigned() {
      this.$bvModal
        .msgBoxConfirm(
          "Marking the Contract as signed will hide all revisions not labeled as active. This cannot be undone.",
          {
            title: "Mark Contract as Signed",
            hideHeaderClose: true,
            okTitle: "Mark Contract as Signed",
            cancelTitle: "Go Back",
            centered: true,
            bodyClass: "modal-padding",
          }
        )
        .then((value) => {
          if (value == true) {
            this.$http
              .get(`/opportunities/${this.opportunityId}/mark-ccdc-signed`)
              .then((response) => {
                //var activeQuoteRevision = this.opportunity.tfrs.find(n => (n.submitted_for_handover == true));
                this.showToast(
                  "success",
                  this.opportunity.address +
                    ", " +
                    this.opportunity.geography +
                    "  has been signed!",
                  "Contract has been signed!"
                );
                this.opportunity.ccdc_signed = true;
              })
              .catch((error) => {
                console.log(error);
                this.showToast("danger", error);
              });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    revertMarkCcdcSigned() {
      this.$http
        .get(`/opportunities/${this.opportunityId}/unmark-ccdc-signed`)
        .then((response) => {
          this.opportunity.ccdc_signed = false;
        })
        .catch((error) => {
          console.log(error);
          this.showToast("danger", error);
        });
    },
    revertSubmittedHandover() {
      this.$http
        .get(`/opportunities/${this.opportunityId}/unsubmit-for-handover`)
        .then((response) => {
          this.opportunity.submitted_for_handover = false;
          this.opportunity.tfrs = response.data.data;
        })
        .catch((error) => {
          console.log(error);
          this.showToast("danger", error);
        });
    },
    badgeClass(status) {
      if (status == "Awaiting Test Fit") {
        return "awaiting-test-fit";
      } else if (status == "Reviewing Test Fit") {
        return "reviewing-test-fit";
      } else if (status == "Archived") {
        return "archived";
      } else if (status == "Awaiting Quote") {
        return "awaiting-quote";
      } else if (status == "Quote in Progress") {
        return "quote-in-progress";
      } else if (status == "Submitted for Handover" || status == "Active") {
        return "submitted-for-handover";
      } else if (status == "Reviewing Quote") {
        return "reviewing-quote";
      } else if (status == "Sent to Client") {
        return "sent-to-client";
      } else if (status == "Hardened") {
        return "hardened";
      } else if (status == "Quote Completed") {
        return "quote-completed";
      }
    },
    toRevisionPage(item, index, event) {
      // if (event.ctrlKey) {
      window.open(`/active/${this.$route.params.id}/${item.id}`, "_blank");
      //   return;
      // }
      // if (event.metaKey) {
      //   window.open(`/active/${this.$route.params.ActiveProjectId}/${item.id}`, "_blank");
      //   return;
      // }
      // window.location.href = `/active/${this.$route.params.ActiveProjectId}/${item.id}`;
    },
    initials(text) {
      let initial;
      if (text != null) {
        initial = text
          .match(/(\b\S)?/g)
          .join("")
          .match(/(^\S|\S$)?/g)
          .join("")
          .toUpperCase();
      } else {
        initial = "N/A";
      }

      return initial;
    },

    formatDatetime(date) {
      return this.$dateF.formatDate(date);
    },
    updateTfrs(tfrs) {
      this.opportunity.tfrs = tfrs;
      this.wonTfr = this.opportunity.tfrs.filter(
        (n) => n.tfr_status == "Quote Completed"
      );
    },
  },
};
</script>

<style>
.custom_btn {
  color:  rgba(92, 177, 231, 1) ;
  height: 38px;
  margin-left: 10px;
}

.list-overview {
  display: flex;
  justify-content: space-between;
  margin-top: 12px;
}

.span-overview {
  flex: 0 0 60%;
  text-align: right;
}

.label-overview {
  font-weight: bold;
}

#modal-duplication.modal.modal-footer {
  display: none;
}

#modal-duplication.wizard-nav.wizard-nav-pills {
  display: none;
}

#tfr-creation.modal.modal-footer {
  display: block;
}

#tfr-creation .wizard-nav.wizard-nav-pills {
  display: flex;
}

.vue-form-wizard .wizard-card-footer {
  border-top: 1px solid #efefef !important;
  padding-top: 11px;
}
.active-class-tab {
  background-color: #f1f1f1 !important;
  border-color: #dae1e7 #dae1e7 transparent;
  border-radius: 5px;
}

#opportunityPage .nav-tabs .nav-link:after {
  height: 0px;
}
#opportunityPage .nav-pills .nav-link,
.nav-tabs .nav-link {
  display: flex;
  align-items: center;
  justify-content: left;
}

#opportunityPage .nav-tabs .nav-link {
  max-width: 250px;
}

.nav-vertical {
  max-height: 850px;
  overflow-y: auto;
}
.modal-padding {
  padding: 0.8rem 1.4rem;
}

.salesforce-avatar {
  background: #dfeeff;
  color: #0070ff;
}
</style>
