<template>
        <div>
            <b-card>
                <div style="padding-right: 15px; padding-left: 15px;">
                <b-row style="padding-top:40px;padding-bottom:28px">
                    <b-col cols="10">
                        <h2 style="font-weight: 500;font-size: 24px;line-height: 24px;display: flex;align-items: center;">Finance Overview</h2>
                        <p style="font-weight: 400;font-size: 14px;line-height: 21px;color: #6E6B7B;"> Revenue and costs for all approved quotes</p>
                    </b-col>
                    <b-col cols="2">
                        <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" block variant="outline-secondary" @click="financeExport" >
                        <uil-file-export
                            class="logo"
                            size="15px"
                            />Export
                        </b-button>
                    </b-col>
                </b-row>
                 <b-row  style="padding-bottom:28px">
                  <b-col cols="4">
                    <label style="font-weight: 600;font-size: 12px;line-height: 23px;letter-spacing: 0.6px;text-transform: uppercase;color: #B9B9C3;">total revenue</label>
                    <h3 class="header-subtotals">{{
                        numberSymbol(revenueTotal) + Number(( revenueTotal ))
                          .toLocaleString(undefined, { minimumFractionDigits: 2 })
                      }}</h3>
                  </b-col>
                  <b-col cols="4">
                    <label style="font-weight: 600;font-size: 12px;line-height: 23px;letter-spacing: 0.6px;text-transform: uppercase;color: #B9B9C3;">total cost</label>
                    <h3 class="header-subtotals">{{
                        numberSymbol(costTotal) + Number(( costTotal ))
                          .toLocaleString(undefined, { minimumFractionDigits: 2 })
                      }}</h3>                    
                  </b-col>
                  <b-col cols="4">
                    <label style="font-weight: 600;font-size: 12px;line-height: 23px;letter-spacing: 0.6px;text-transform: uppercase;color: #B9B9C3;">total profit</label>
                    <h3 class="header-subtotals">{{
                        numberSymbol(( revenueTotal - costTotal )) + Number(( revenueTotal - costTotal ))
                          .toLocaleString(undefined, { minimumFractionDigits: 2 })
                      }}</h3>                    
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="12">
                    <b-table-simple
                        caption-top
                        responsive
                        style="overflow-y: hidden;"
                    >
                        <b-thead>
                            <b-tr>
                            <b-th
                                class="table-background-color"
                                
                            >
                                Quote name
                            </b-th>
                            <b-th
                                class="table-background-color"
                                colspan="3"
                            >
                                
                            </b-th>
                            <b-th
                                class="table-background-color"
                                
                            >
                                date approved
                            </b-th>
                            <b-th
                                class="table-background-color text-center"
                                
                            >
                                CSTRN
                            </b-th>
                            <b-th
                                class="table-background-color text-center"
                                
                            >
                                FF&E
                            </b-th>
                            <b-th
                                class="table-background-color text-center"
                                
                            >
                                AV/IT
                            </b-th>
                            <b-th
                                class="table-background-color text-center"
                                
                            >
                                CHQ
                            </b-th>
                            <b-th
                                class="table-background-color text-center"
                                
                            >
                                OTH
                            </b-th>
                            <b-th
                                class="table-background-color text-center"
                                
                            >
                                Quote Total
                            </b-th>
                            
                            </b-tr>
                        </b-thead>
                        <b-tbody>
                            <b-tr class="">
                                <b-th
                                    class="row-label"
                                    rowspan="2"
                                    colspan="3"
                                >
                                    Quote {{quote.tfr_name}}
                                </b-th>
                                <b-th class="text-right">Revenue</b-th>
                                <b-td class="text-right">{{ formattedDate(quote.updated_at)}}</b-td>
                                <b-td class="text-right">{{'$' + Number((((quote.construction_buffer*1))* quote.sqft) + quote.construction_calc + (siteConditionsConstructionPriceTotal) + optionsConstructionPriceTotal).toLocaleString(undefined, { minimumFractionDigits: 2 })}}</b-td>
                                <b-td class="text-right">{{'$' + Number(( (!quote.ffe_toggle ? 0 : quote.ffe_calc)  + optionsFfePriceTotal)).toLocaleString(undefined, { minimumFractionDigits: 2 })}}</b-td>
                                <b-td class="text-right">{{'$' + Number(( (!quote.avit_toggle ? 0 : quote.avit_calc) + optionsAvItPriceTotal)).toLocaleString(undefined, { minimumFractionDigits: 2 })}}</b-td>
                                <b-td class="text-right">{{'$' + Number(((quote.construction_pm_buffer * 1) + (quote.design_ame_buffer * 1) + Number(quote.engineering_fee_price)) * quote.sqft).toLocaleString(undefined, { minimumFractionDigits: 2 })}}</b-td>
                                <b-td class="text-right">{{'$' + Number(quote.permit_fees).toLocaleString(undefined, { minimumFractionDigits: 2 })}}</b-td>
                                <b-td class="quote-totals">{{'$' +Number(((Number(((quote.construction_pm_buffer * 1) + (quote.design_ame_buffer * 1) + (Number(quote.engineering_fee_price) * 1))))* quote.sqft)+Number(quote.permit_fees)+Number(( (!quote.ffe_toggle ? 0 : quote.ffe_calc)  + optionsFfePriceTotal))+Number((!quote.avit_toggle ? 0 : quote.avit_calc) + optionsAvItPriceTotal)+Number((((quote.construction_buffer*1))* quote.sqft) + quote.construction_calc + (siteConditionsConstructionPriceTotal) + optionsConstructionPriceTotal)  ).toLocaleString(undefined, { minimumFractionDigits: 2 })}}</b-td>
                                
                            </b-tr>
                            <b-tr class="">
                                <b-th class="text-right">Cost</b-th>
                                <b-td></b-td>
                                <b-td class="table-cost-values">{{
                                '$' + Number(quote.construction_calc + siteConditionsConstructionCostTotal + optionsConstructionCostTotal)
                                  .toLocaleString(undefined, { minimumFractionDigits: 2 })
                                }}</b-td>
                                <b-td class="table-cost-values">{{
                                  '$' + Number((!quote.ffe_toggle ? 0 : ffeTotalCost + quote.ffe_installation ) + optionsFfeCostTotal )
                                      .toLocaleString(undefined, { minimumFractionDigits: 2 })
                                  }}</b-td>
                                <b-td class="table-cost-values">{{
                                  '$' + Number((((!quote.avit_toggle ? 0 : avitTotalCost + quote.avit_installation)) + optionsAvItCostTotal ))
                                    .toLocaleString(undefined, { minimumFractionDigits: 2 })
                                }}</b-td>
                                <b-td class="table-cost-values">{{
                                  '$' + Number((((quote.engineering_fee_cost) * 1)*quote.sqft))
                                    .toLocaleString(undefined, { minimumFractionDigits: 2 })
                                }}</b-td>
                                <b-td class="table-cost-values">{{
                                  '$' + Number((quote.permit_fees_cost_per_sqft)*quote.sqft)
                                    .toLocaleString(undefined, { minimumFractionDigits: 2 })
                                }}</b-td>
                                <b-td class="quote-totals-cost">{{
                                  '$' +
                                    Number( (quote.construction_calc + siteConditionsConstructionCostTotal + optionsConstructionCostTotal)
                                      + ((!quote.ffe_toggle ? 0 : ffeTotalCost + quote.ffe_installation ) + optionsFfeCostTotal )
                                      + ((((!quote.avit_toggle ? 0 : avitTotalCost  + quote.avit_installation)) + optionsAvItCostTotal))
                                      + ((((quote.engineering_fee_cost) * 1)*quote.sqft))
                                      + ((quote.permit_fees_cost_per_sqft)*quote.sqft)
                                    ).toLocaleString(undefined, { minimumFractionDigits: 2 })
                                }}</b-td>
                            </b-tr>
                            <template v-for="(changeOrder,index) in this.approvedChangeOrders">
                              <b-tr :key="index + 'revenue'">
                                  <b-th
                                      class="row-label"
                                      rowspan="2"
                                      colspan="3"
                                  >
                                      Change Order {{changeOrder.change_order_number}}
                                  </b-th>
                                  <b-th class="text-right">Revenue</b-th>
                                  <b-td class="text-right">{{ formattedDate(changeOrder.updated_at) }}</b-td>
                                  <b-td class="text-right">{{changeOrderConstructionPriceTotal(changeOrder)}}</b-td>
                                  <b-td class="text-right">{{changeOrderFfePriceTotal(changeOrder)}}</b-td>
                                  <b-td class="text-right">{{changeOrderAvItPriceTotal(changeOrder)}}</b-td>
                                  <b-td class="text-right">{{changeOrderCHQPriceTotal(changeOrder)}}</b-td>
                                  <b-td class="table-cost-values">{{  '$' + Number(0.00).toLocaleString(undefined, { minimumFractionDigits: 2 })}}</b-td>
                                  <b-td class="quote-totals">{{changeOrderQuotePriceTotal(changeOrder)}}</b-td>
                              </b-tr>
                              <b-tr :key="index + 'cost'">
                                  <b-th class="text-right">Cost</b-th>
                                  <b-td></b-td>
                                  <b-td class="table-cost-values">{{changeOrderConstructionCostTotal(changeOrder)}}</b-td>
                                  <b-td class="table-cost-values">{{changeOrderFfeCostTotal(changeOrder)}}</b-td>
                                  <b-td class="table-cost-values">{{changeOrderAvItCostTotal(changeOrder)}}</b-td>
                                  <b-td class="table-cost-values">{{changeOrderSoftCostsCostTotal(changeOrder)}}</b-td>
                                  <b-td class="table-cost-values">{{ '$' +  Number(0.00).toLocaleString(undefined, { minimumFractionDigits: 2 })}}</b-td>
                                  <b-td class="quote-totals-cost">{{changeOrderCHQCostTotal(changeOrder)}}</b-td>
                              </b-tr>
                            </template>
                        </b-tbody>
                    </b-table-simple>
                  </b-col>
                  
                </b-row>
                </div>
            </b-card>
        </div>
          
</template>

<script>
import {
  UilFileExport
} from '@iconscout/vue-unicons'
import {
    BButton,
    BCard,
    BCardBody,
    BCol,
    BRow,
    BTableSimple,
    BTbody,
    BTd,
    BTfoot,
    BTh,
    BThead,
    BTr,
    VBToggle,
    BTable,
    BFormGroup,
    BFormRadioGroup,
            BInputGroup,
        BFormInput,
        BInputGroupPrepend,
} from 'bootstrap-vue'
import Logo from '@core/layouts/components/Logo.vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItemDPMOutput.vue'
import moment from 'moment'

export default {
    directives: {
        Ripple,
        'b-toggle': VBToggle,
    },
    components: {
        BRow,
        BCol,
        BCard,
        BCardBody,
        BTableSimple,
        BButton,
        BTr,
        BTh,
        BThead,
        BTfoot,
        BTd,
        BTbody,
        Logo,
        AppCollapse,
        AppCollapseItem,
        BTable,
        BFormGroup,
        BFormRadioGroup,
        BInputGroup,
        BFormInput,
        BInputGroupPrepend,
        UilFileExport,
    },
    props: ['quote',"opportunity","approvedChangeOrders"],
    data() {
        return {
            quoteId: this.$route.params.quoteid,
            comments: {},
            show: true,
            newComment: '',
            assumptionsExclusions : {},
            selectedRadio: 'all',
            filter: null,
        }
    },
    beforeMount() {
    },
    computed: {
      ffeTotalCost() {
        const results = this.quote.ffe_spec_quote.reduce((total, obj) => (obj.total_cost*1) + total,0);
        return results;
      },
      avitTotalCost() {
        const results = this.quote.avit_spec_quote.reduce((total, obj) => (obj.total_cost*1) + total,0);
        return results;
      },
      siteConditionsConstructionCostTotal() {
          const results = this.quote.site_conditions.filter(x => ((x.budget_group === "Construction"))).reduce((total, obj) => (obj.total_cost*1) + total,0);
          return results          
      },
      siteConditionsConstructionPriceTotal() {
          const results = this.quote.site_conditions.filter(x => ((x.budget_group === "Construction"))).reduce((total, obj) => (obj.total_price*1) + total,0);
          return results          
      },
      optionsConstructionCostTotal() {
          const results = this.quote.options.filter(x => ((x.budget_group === "Construction"))).reduce((total, obj) => (obj.total_cost*1) + total,0);
          return results          
      },
      optionsConstructionPriceTotal() {
          const results = this.quote.options.filter(x => ((x.budget_group === "Construction"))).reduce((total, obj) => (obj.total_price*1) + total,0);
          return results          
      },
      optionsAvItCostTotal() {
          const results = this.quote.options.filter(x => ((x.budget_group === "AV/IT"))).reduce((total, obj) => (obj.total_cost*1) + total,0);
          return results          
      },
      optionsAvItPriceTotal() {
          const results = this.quote.options.filter(x => ((x.budget_group === "AV/IT"))).reduce((total, obj) => (obj.total_price*1) + total,0);
          return results          
      },
      optionsFfeCostTotal() {
          const results = this.quote.options.filter(x => ((x.budget_group === "FF&E"))).reduce((total, obj) => (obj.total_cost*1) + total,0);
          return results          
      },
      optionsFfePriceTotal() {
          const results = this.quote.options.filter(x => ((x.budget_group === "FF&E") )).reduce((total, obj) => (obj.total_price*1) + total,0);
          return results          
      },
      ccdcQuoteRevenueTotal(){
        return Number(((((this.quote.construction_pm_buffer * 1) + (this.quote.design_ame_buffer * 1) + (Number(this.quote.engineering_fee_price) * 1)))* this.quote.sqft)+Number(this.quote.permit_fees)+Number(( (!this.quote.ffe_toggle ? 0 : this.quote.ffe_calc)  + this.optionsFfePriceTotal))+Number((!this.quote.avit_toggle ? 0 : this.quote.avit_calc) + this.optionsAvItPriceTotal)+Number((((this.quote.construction_buffer*1))* this.quote.sqft) + this.quote.construction_calc + (this.siteConditionsConstructionPriceTotal) + this.optionsConstructionPriceTotal))
      },
      ccdcQuoteCostTotal(){
        return Number( (this.quote.construction_calc + this.siteConditionsConstructionCostTotal + this.optionsConstructionCostTotal) + ((!this.quote.ffe_toggle ? 0 : this.ffeTotalCost + this.quote.ffe_installation ) + this.optionsFfeCostTotal ) + ((((!this.quote.avit_toggle ? 0 : this.avitTotalCost  + this.quote.avit_installation)) + this.optionsAvItCostTotal)) + ((((this.quote.engineering_fee_cost) * 1)*this.quote.sqft)) + ((this.quote.permit_fees_cost_per_sqft)*this.quote.sqft))
      },
      revenueTotal() {
        let changeOrdersTotal = 0;
        this.opportunity.change_orders.filter(x => (x.status === "Approved")).forEach(e => {
            changeOrdersTotal += parseFloat(e.change_order_lines.reduce((total, obj) => (obj.total_price*1) + total,0));
            changeOrdersTotal += e.admin_fee_enabled ? (e.admin_fee_subtotal) : 0;
        });
        changeOrdersTotal += this.ccdcQuoteRevenueTotal;
        return parseFloat(changeOrdersTotal.toFixed(2))
      },
      costTotal() {
        let changeOrdersTotal = 0;
        this.opportunity.change_orders.filter(x => (x.status === "Approved")).forEach(e => {
            changeOrdersTotal += parseFloat(e.change_order_lines.reduce((total, obj) => (obj.total_cost*1) + total,0));
        });
        changeOrdersTotal += this.ccdcQuoteCostTotal;
        return parseFloat(changeOrdersTotal.toFixed(2))
      }, 
    },

    methods: {
        financeExport() {
            this.$http({
                url: `/opportunities/${this.opportunity.sf_opportunity_id}/finance-export`,
                method: 'GET',
                responseType: 'blob',
            }).then((res) => {
                var fileURL = window.URL.createObjectURL(new Blob([res.data]));
                var fileLink = document.createElement('a');

                fileLink.href = fileURL;
                fileLink.setAttribute('download',  `Finance Export  #${this.opportunity.sf_opportunity_id}`+'.xlsx');
                document.body.appendChild(fileLink);

                fileLink.click();
            });
        },
        changeOrderConstructionCostTotal(changeOrder) {
            const total = changeOrder.change_order_lines.filter(x => ((x.budget_group === "Construction"))).reduce((total, obj) => (obj.total_cost*1) + total,0);
            const formattedNumber = this.numberSymbol(total) + Number(Math.abs(total)).toLocaleString(undefined, { minimumFractionDigits: 2 });
            return formattedNumber          
        },
        changeOrderConstructionPriceTotal(changeOrder) {
            const total = changeOrder.change_order_lines.filter(x => ((x.budget_group === "Construction"))).reduce((total, obj) => (obj.total_price*1) + total,0);
            const formattedNumber = this.numberSymbol(total) + Number(Math.abs(total)).toLocaleString(undefined, { minimumFractionDigits: 2 });
            return formattedNumber          
        },
        changeOrderFfeCostTotal(changeOrder) {
            const total = changeOrder.change_order_lines.filter(x => ((x.budget_group === "FF&E"))).reduce((total, obj) => (obj.total_cost*1) + total,0);
            const formattedNumber = this.numberSymbol(total) + Number(Math.abs(total)).toLocaleString(undefined, { minimumFractionDigits: 2 });
            return formattedNumber          
        },
        changeOrderFfePriceTotal(changeOrder) {
            const total = changeOrder.change_order_lines.filter(x => ((x.budget_group === "FF&E"))).reduce((total, obj) => (obj.total_price*1) + total,0);
            const formattedNumber = this.numberSymbol(total) + Number(Math.abs(total)).toLocaleString(undefined, { minimumFractionDigits: 2 });
            return formattedNumber          
        },
        changeOrderAvItCostTotal(changeOrder) {
            const total = changeOrder.change_order_lines.filter(x => ((x.budget_group === "AV/IT"))).reduce((total, obj) => (obj.total_cost*1) + total,0);
            const formattedNumber = this.numberSymbol(total) + Number(Math.abs(total)).toLocaleString(undefined, { minimumFractionDigits: 2 });
            return formattedNumber          
        },
        changeOrderAvItPriceTotal(changeOrder) {
            const total = changeOrder.change_order_lines.filter(x => ((x.budget_group === "AV/IT"))).reduce((total, obj) => (obj.total_price*1) + total,0);
            const formattedNumber = this.numberSymbol(total) + Number(Math.abs(total)).toLocaleString(undefined, { minimumFractionDigits: 2 });
            return formattedNumber          
        },
        changeOrderSoftCostsCostTotal(changeOrder) {
            const total = changeOrder.change_order_lines.filter(x => ((x.budget_group === "Soft Costs"))).reduce((total, obj) => (obj.total_cost*1) + total,0);
            const formattedNumber = this.numberSymbol(total) + Number(Math.abs(total)).toLocaleString(undefined, { minimumFractionDigits: 2 });
            return formattedNumber          
        },
        changeOrderCHQPriceTotal(changeOrder) {
            let total = changeOrder.change_order_lines.filter(x => ((x.budget_group === "Soft Costs"))).reduce((total, obj) => (obj.total_price*1) + total,0);
            let totalAdminFee = changeOrder.admin_fee_enabled ? (changeOrder.admin_fee_subtotal) : 0;
            total += totalAdminFee;
            const formattedNumber = this.numberSymbol(total.toFixed(2)) + Number(Math.abs(total.toFixed(2))).toLocaleString(undefined, { minimumFractionDigits: 2 });
            return formattedNumber          
        },
        changeOrderCHQCostTotal(changeOrder) {
            const total = changeOrder.change_order_lines.reduce((total, obj) => (obj.total_cost*1) + total,0);
            const formattedNumber = this.numberSymbol(total) + Number(Math.abs(total)).toLocaleString(undefined, { minimumFractionDigits: 2 });
            return formattedNumber          
        },
        changeOrderQuotePriceTotal(changeOrder) {
            let total = changeOrder.change_order_lines.reduce((total, obj) => (obj.total_price*1) + total,0);
            let totalAdminFee = changeOrder.admin_fee_enabled ? (changeOrder.admin_fee_subtotal) : 0;
            total += totalAdminFee;
            const formattedNumber = this.numberSymbol(total.toFixed(2)) + Number(Math.abs(total.toFixed(2))).toLocaleString(undefined, { minimumFractionDigits: 2 });
            return formattedNumber          
        },
        formattedDate(date) {
            return moment(date).format("MMMM DD, YYYY")
        },
        numberSymbol(number){
            return [(number < 0) ? '-$' : '$']
        }
    },

}
</script>

<style scoped>
.cost-line {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #B9B9C3;
}
.cost-header {
  font-weight: 600;
  font-size: 12px;
  line-height: 12px;
  color: #B9B9C3;
}

.header-subtotals{
    font-weight: 500 !important;
    font-size: 21px !important;
    line-height: 21px !important;
    color: #6E6B7B !important;
}

.table-cost-values{
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    text-align: right;
    color: #B9B9C3;
}

.quote-totals{
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    text-align: right;
    color:  rgba(92, 177, 231, 1) ;
}


.quote-totals-cost{
font-style: normal;
font-weight: 600;
font-size: 14px;
line-height: 21px;
text-align: right;
color: #B9B9C3;
}
</style>
